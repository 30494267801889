<template>
  <el-dialog
    :title="title"
    :visible.sync="showDialog"
    width="650px"
    :closeOnClickModal="false"
    append-to-body
    @open="initData"
    :before-close="closeFn"
    ><el-form :model="form" ref="ruleForm" :rules="rules" label-width="100px">
      <el-form-item label="资源编码：" prop="code">
        <div class="flex-start">
          <el-input
            v-model.trim="form.code"
            placeholder="请输入资源编码"
            clearable
            maxlength="64"
            show-word-limit
            class="inputWidth"
          /><label style="line-height: 1.2; margin-left: 6px"
            >(字母数字下划线组合)</label
          >
        </div>
      </el-form-item>
      <el-form-item label="资源名称：" prop="name">
        <el-input
          v-model.trim="form.name"
          placeholder="请输入资源名称"
          clearable
          maxlength="64"
          show-word-limit
          class="inputWidth"/></el-form-item
      ><el-form-item label="资源描述：">
        <el-input
          type="textarea"
          v-model.trim="form.description"
          placeholder="请输入资源描述"
          clearable
          maxlength="255"
          :rows="4"
          show-word-limit
          class="inputWidth"/></el-form-item
    ></el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="sureFn">确 定</el-button>
      <el-button @click="closeFn"> 取 消</el-button>
    </span></el-dialog
  >
</template>

<script>
export default {
  name: 'add_space_source',
  props: {
    showDialog: false,
    isEdit: Boolean,
    sourceData: Object
  },
  data() {
    let checkCode = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入资源编码'));
      } else {
        let reg = /^[a-zA-Z_0-9]+$/;
        if (!reg.test(value)) {
          callback(new Error('编码不符合规范'));
        } else {
        }
        callback();
      }
    };
    return {
      form: {
        name: '',
        description: '',
        code: '',
        // parentId: null,

        disabled: 0
      },
      rules: {
        name: [{ required: true, message: '请输入资源名称', trigger: 'blur' }],
        code: [{ required: true, validator: checkCode, trigger: 'blur' }]
      },
      title: ''
    };
  },
  mounted() {},
  methods: {
    initData() {
      this.$nextTick(() => {
        this.$refs.ruleForm.resetFields();
        if (this.isEdit) {
          this.form.name = this.sourceData.name;
          this.form.code = this.sourceData.code;
          this.form.description = this.sourceData.description;
        } else {
          this.form.name = '';
          this.form.code = '';
          this.form.description = '';
        }
        this.title = this.isEdit ? '编辑空间资源' : '添加空间资源';
      });
    },
    closeFn() {
      this.$emit('closeDialog');
    },
    sureFn() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.$emit('sure', this.form);
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.inputWidth {
  width: 350px;
}
</style>
